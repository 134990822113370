(function (App, $, TweenLite, window, document) {
  /** @type {Boolean} Is local debug active? */
  const localDebug = false;
  /** @type {Boolean} Is debug active? */
  const debug = App.hasDebug() && localDebug;
  /** @type {Function} Log helper */
  const { log } = App.components.Debugger(debug, 'FullscreenPushTop');

  /**
   * Push top class
   */
  function FullscreenPushTop() {
    if (!(this instanceof FullscreenPushTop)) return new FullscreenPushTop();
    log('constructor');

    this.$push = document.getElementById('js-fullscreen-push-top');
    if (this.$push === null) {
      log('There is no push top.');
      return false;
    }

    // Set vars
    this.hasScrolled = false;
    this.isTicking = false;
    this.scrollTimer = 0;
    this.$page = document.getElementById('page');

    // Bind all methods to self
    this.tick = this.tick.bind(this);
    this.resetTick = this.resetTick.bind(this);
    this.disable = this.disable.bind(this);
    this.scrollHandler = this.scrollHandler.bind(this);
    this.loadHandler = this.loadHandler.bind(this);
    this.clickHandler = this.clickHandler.bind(this);

    // Listen passively to scroll
    window.addEventListener('scroll', this.scrollHandler, { passive: true });
    window.addEventListener('load', this.loadHandler);

    // Bind button click if it exists
    const $btn = document.getElementById('js-fullscreen-push-top__btn');
    if ($btn !== null) {
      $btn.addEventListener('click', this.clickHandler);
    }

    return this;
  }

  /**
   * Loop to check on state
   */
  FullscreenPushTop.prototype.tick = function () {
    log('tick');

    if (!this.isTicking) {
      return false;
    }

    const y = window.pageYOffset / -1.5;
    this.$push.style.transform = `translate3d(0, ${y}px, 0)`;

    if (window.pageYOffset > App.helpers.headerHeight) {
      this.disable();
    }

    return requestAnimationFrame(this.tick);
  };

  /**
   * Disable tick
   */
  FullscreenPushTop.prototype.resetTick = function () {
    log('resetTick');
    this.isTicking = false;
  };

  /**
   * Handle scroll on window
   */
  FullscreenPushTop.prototype.scrollHandler = function () {
    log('scrollHandler');

    if (!this.isTicking && !this.hasScrolled) {
      this.isTicking = true;
      this.tick();
    }

    // Stop tick when not scrolling
    clearTimeout(this.scrollTimer);
    this.scrollTimer = setTimeout(this.resetTick, 1000);
  };

  /**
   * Handle animations on window load
   */
  FullscreenPushTop.prototype.loadHandler = function () {
    log('loadHandler');

    $(this.$push).addClass('is-loaded');
  };

  /**
   * Handle click on got to content button
   * @param  {Object} e The event's object
   */
  FullscreenPushTop.prototype.clickHandler = function () {
    return this.disable();
  };

  /**
   * Disable the push
   */
  FullscreenPushTop.prototype.disable = function () {
    log('disable');
    this.isTicking = false;
    this.hasScrolled = true;
    window.scrollTo(0, 0);
    this.$page.style.transform = 'none';
    this.$push.style.display = 'none';
    this.$push.classList.add('is-hidden');
    window.removeEventListener('scroll', this.scrollHandler);
  };

  // Init a new instance
  // as soon as possible
  App.components.fullScreenPushTop = new FullscreenPushTop();
})(App, jQuery, TweenLite, window, document);
